/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px
}
.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: #4caf50;
}
.mat-accent .mat-slider-thumb {
  height: 30px;
  width: 30px;
  background-color: white;
  border: solid 2px gray;
  bottom: -20px;
  right: -20px;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}
html, body { height: 100%; }
body { margin: 0;  font-family: "Poppins", sans-serif; }

markdown{ 
 img{
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
 }

 .video_container{

  video{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  }
 }
} 
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import "swiper/swiper.min.css";




html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

swiper-container {
  width: 100%;
  height: 300px;
  // margin: 50px auto;
}

swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 0px !important;
}

swiper-slide {
  background: #f1f1f1;
  color: #000;
  text-align: center;
  line-height: 300px;
}
/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #5788d4; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #233876; 
}



